import React, { useState } from "react";
import { useContext } from "react";
import { CMSContext } from "../BlogPage/index.js";
import { useNavigate } from "react-router-dom";

const AdminLogin = () => {
  const [token, setToken] = useState("");
  const { cmsAPI } = useContext(CMSContext);
  const navigate = useNavigate();

  const handletokenChange = (e) => {
    setToken(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Submitted", { token });
    fetch(`${cmsAPI}/admin/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: token,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.token) {
          // Store the token in localStorage
          localStorage.setItem("token", data.token);
          console.log("Token stored successfully");
          navigate("/blog/admin/bloglist");
        } else {
          console.error("Token not received in response:", data);
          // Handle the case where token is not in the response
        }
      })
      .catch((error) => {
        console.error("Error during post creation:", error);
        // Handle error state in the UI here
      });

    // Add your form submission logic here
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900 flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <a
        href="#"
        className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
      >
        Blurgs.ai
      </a>
      <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            Sign in to your account
          </h1>
          <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="token"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Your token
              </label>
              <input
                type="token"
                name="token"
                id="token"
                className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="token@321"
                required
                onChange={handletokenChange}
                value={token}
              />
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-start">
                {/* <div className="flex items-center h-5">
                  <input id="remember" aria-describedby="remember" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required />
                </div> */}
                {/* <div className="ml-3 text-sm">
                  <label htmlFor="remember" className="text-gray-500 dark:text-gray-300">Remember me</label>
                </div> */}
              </div>
              <a
                href="#"
                className="text-sm font-medium text-primary-600 hover:underline dark:text-gray-400"
              >
                Forgot Token ?
              </a>
            </div>
            <button
              type="submit"
              className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              Sign in
            </button>
            <p className="text-sm font-light text-gray-500 dark:text-gray-400">
              Don’t have an account yet?{" "}
              <a
                href="#"
                className="font-medium text-primary-600 hover:underline dark:text-primary-500"
              >
                Sign up
              </a>
            </p>
          </form>
        </div>
      </div>
    </section>
  );
};

export default AdminLogin;

import './footer.css';

export function Footer() {
  return (
    <div className="bg-[#E6F1FF] pt-16">
      <div className="lg:mx-48 xs:mx-4 lg:flex justify-between lg:space-x-44">
        <div className="lg:w-1/5">
          <img src="/dhoopassets/icons/dhoop-logo.png" className="h-16 lg:mb-12 mb-4 object-contain" />
          <p className="font-medium xs:text-md lg:text-xl">
            Blurgs Innovations, Goodworks Infinity Park Electronic city phase 1,
            Bengaluru, Karnataka, India - 560100
          </p>
        </div>
        <div className='hidden lg:block'>
            <p className="font-semibold text-[1.75em] mb-1">Quick Links</p>
            <p className="font-medium text-2xl leading-10 mb-0">Home</p>
            <p className="font-medium text-2xl leading-10 mb-0">Product</p>
            <p className="font-medium text-2xl leading-10 mb-0">Features</p>
            <p className="font-medium text-2xl leading-10 mb-0">About Us</p>
        </div>
        <div className='hidden lg:block'>
            <p className="font-semibold text-[1.75em] mb-1">Policy</p>
            <p className="font-medium text-2xl leading-10 mb-0">Terms of use</p>
            <p className="font-medium text-2xl leading-10 mb-0">Privacy Policy</p>
        </div>
        <div className="flex flex-col lg:items-end xs:mt-10 lg:mt-0 lg:space-y-5 xs:space-y-1">
        
            <div className="flex space-x-5 xs:mb-2 lg:mb-0 items-center">
                <img className="lg:h-8 xs:h-6 object-contain" src="/dhoopassets/icons/twitterlogo.png" />
                <img className="lg:h-8 xs:h-6 object-contain" src="/dhoopassets/icons/linkedinlogo.png" />
                <div className='xs:block lg:hidden mb-2'><img className="h-10  object-contain" src="/dhoopassets/icons/blurgslogo.png" /></div>
            </div>
            
            <p className="font-semibold lg:text-2xl xs:text-md">dhoop@blurgs.com</p>
            <p className="font-semibold lg:text-2xl xs:text-md">abhi.yadav@blurgs.com</p>
            <div>
            <img className="h-16 xs:hidden lg:block object-contain" src="/dhoopassets/icons/blurgslogo.png" />
            </div>
        </div>

        <div className='xs:flex space-x-2 lg:hidden text-md mt-2'>
            <p className="font-medium mb-1">Terms of use</p>
            <p className="font-medium">Privacy Policy</p>
        </div>
      </div>
      <div className="mt-9 xs:mx-4 lg:mx-0">
            <p style={{
                'fontFamily' : 'syne',
            }} className="text-md font-medium text-center mb-0">
                Copyright © 2024 Blurgs Innovations - All Rights Reserved</p>
        </div>
    </div>
  );
}

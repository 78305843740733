

export function DoubleHeading({ text }) {
    return <div>
        <h1 className="text-center hidden 2xl:block leading-8 font-bold text-8xl" style={{
            
            color: "rgba(68, 132, 207, 0.2)"
        }}>{text.toUpperCase()}</h1>
        <h1 className="text-center font-bold text-5xl" style={{
            color: "rgba(0, 67, 147, 1)"
        }}>{text}</h1>
    </div>
}
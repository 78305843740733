import { Jumbotron } from './jumbotron';
import { WhatIsDhoop } from './whatisdhoop';
import { WhyChooseDhoop } from './whychoosedhoop';
import { GetInTouch } from './getintouch';
import { HowItWorks } from './howitworks';
import { BookADemo } from './bookademo';
import { Footer } from './footer';

function DhoopLandingPage(){
    return (
        <div style={{'font-family': 'Montserrat, sans-serif'}}>
            <Jumbotron />
            <WhatIsDhoop />
            <WhyChooseDhoop />
            <GetInTouch />
            <HowItWorks />
            <BookADemo />
            <Footer/>
        </div>
    )   
}

export default DhoopLandingPage;
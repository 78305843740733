export function IconCard({ icon, title, description }) {
  return (
    <div
      className="bg-white p-4 rounded-xl lg:w-1/4"
      style={{
        boxShadow: "2px 2px 2px 0px rgba(0,0,0,0.2)",
      }}
    >
      <img src={icon} className="mb-1 w-12" />
      <div>
        <h5 className="font-bold">{title}</h5>
        <p className="mt-3 font-semibold text-grey">
          {description}
        </p>
      </div>
    </div>
  );
}

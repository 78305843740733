// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  
  .imgWrapper {
    width: 400px;
    height: 15rem;
    margin: 0 15px;
    object-fit: cover;
  }
  
  .imgWrapper:last-of-type {
    padding-left: 0;
  }


  
  .inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 15rem;
  }


  
  .wrapper {
    position: absolute;
    display: flex;
  }
  
  bannersection {
    display: flex;
    animation: swipe var(--speed) linear infinite backwards;
  }
  


  @keyframes swipe {
    0% {
      transform: translate(0);
    }
  
    100% {
      transform: translate(-100%);
    }
  }
  

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.5);
  }

`, "",{"version":3,"sources":["webpack://./src/Components/Banner.css"],"names":[],"mappings":";;EAEE;IACE,YAAY;IACZ,aAAa;IACb,cAAc;IACd,iBAAiB;EACnB;;EAEA;IACE,eAAe;EACjB;;;;EAIA;IACE,kBAAkB;IAClB,WAAW;IACX,gBAAgB;IAChB,aAAa;EACf;;;;EAIA;IACE,kBAAkB;IAClB,aAAa;EACf;;EAEA;IACE,aAAa;IACb,uDAAuD;EACzD;;;;EAIA;IACE;MACE,uBAAuB;IACzB;;IAEA;MACE,2BAA2B;IAC7B;EACF;;;EAGA;IACE,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,8BAA8B;EAChC","sourcesContent":["\n  \n  .imgWrapper {\n    width: 400px;\n    height: 15rem;\n    margin: 0 15px;\n    object-fit: cover;\n  }\n  \n  .imgWrapper:last-of-type {\n    padding-left: 0;\n  }\n\n\n  \n  .inner {\n    position: relative;\n    width: 100%;\n    overflow: hidden;\n    height: 15rem;\n  }\n\n\n  \n  .wrapper {\n    position: absolute;\n    display: flex;\n  }\n  \n  bannersection {\n    display: flex;\n    animation: swipe var(--speed) linear infinite backwards;\n  }\n  \n\n\n  @keyframes swipe {\n    0% {\n      transform: translate(0);\n    }\n  \n    100% {\n      transform: translate(-100%);\n    }\n  }\n  \n\n  .overlay {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    border-radius: 10px;\n    background: rgba(0, 0, 0, 0.5);\n  }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { useState, useEffect } from "react";

export function BlogPathInputField({defaultValue,disabled=false}) {
  const [blogPath, setBlogPath] = useState("");
  useEffect(() => { 
    setBlogPath(defaultValue || '');  // Set the initial state to defaultValue when the component mounts
  }, [defaultValue]);  // Reacting on defaultValue changes


  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">
        Blog Path
      </label>
      <input
        type="text"
        value={blogPath}
        name="blogpath"
        disabled={disabled}
        onChange={(e) => setBlogPath(e.target.value)}
        placeholder="understanding-the-role-of-ai-in-maritime-security"
        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      />
      {/* path will be blurgs.ai/blog/{blogPath} */}
      {blogPath ? (
        <small className="text-gray-500">
          Path will be blurgs.ai/blog/{blogPath}
          <br />
        </small>
      ) : (
        <small className="text-red-600 font-bold">Path cannot be empty</small>
      )}
    </div>
  );
}

// Object { date: "June 1, 2023", duration: "5 min", image: "https://cdn.pixabay.com/photo/2022/12/20/17/27/sheet-7668351_640.jpg", postpath: "understanding-the-role-of-ai-in-maritime-security", table_of_contents: (5) […], title: "Understanding the Role of AI in Maritime Security" }
// ​
// date: "June 1, 2023"
// ​
// duration: "5 min"
// ​
// image: "https://cdn.pixabay.com/photo/2022/12/20/17/27/sheet-7668351_640.jpg"
// ​
// postpath: "understanding-the-role-of-ai-in-maritime-security"
// ​
// table_of_contents: Array(5) [ {…}, {…}, {…}, … ]
// ​​
// 0: Object { content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut\nlabore et dolore magna aliqua. Pellentesque nec nam aliquam sem et tortor consequat id\nporta. Fringilla est ullamcorper eget nulla facilisi etiam dignissim. Tristique sollicitudin nibh sit\namet commodo. Posuere sollicitudin aliquam ultrices sagittis orci. Tincidunt lobortis feugiat\nvivamus at augue eget. Lorem sed risus ultricies tristique. Ipsum consequat nisl vel pretium\nlectus quam id. Dignissim suspendisse in est ante in nibh mauris.", image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQj78MIv4S_1OG1VrivVAkJEXxQaMxwlTuOYQ&s", title: "The Importance of Maritime Security" }
// ​​
// 1: Object { title: '"How AI Enhances Maritime Surveillance"' }
// ​​
// 2: Object { content: "AI offers numerous benefits in maritime operations:\n\n    1. Enhanced Threat Detection: Identifies suspicious activities early.\n\n    2. Efficient Resource Allocation: Optimizes the deployment of security assets.\n\n    3. Predictive Analytics: Forecasts potential security incidents.\n\n    4. Cost Savings: Reduces operational costs through automation.\n\n    5. Improved Decision-Making: Provides actionable insights.", title: "Key Benefits of AI in Maritime Operations" }
// ​​
// 3: Object { content: "Several ports and maritime organizations have successfully implemented AI solutions. For instance, the Port of Rotterdam uses AI to optimize traffic management and enhance security. Similarly, the US Coast Guard employs AI to improve its surveillance capabilities and respond more effectively to maritime threats.", title: "Case Studies: Successful AI Implementation" }
// ​​
// 4: Object { content: "The future of AI in maritime security looks promising with advancements in machine learning, data analytics, and autonomous systems. Emerging technologies such as blockchain for secure data sharing and AI-driven drones for enhanced surveillance are set to revolutionize the industry. Continuous innovation and collaboration will be key to tackling the evolving challenges in maritime security.", title: "Future Trends in AI for Maritime Security" }
// ​​
// length: 5
// ​​
// <prototype>: Array []
// ​
// title: "Understanding the Role of AI in Maritime Security"
// ​
// <prototype>: Object { … }
// index.js:41

function FormattedContent({ content }) {
  // if content has 1. 2. 3.  put line by line
  if (content.split("\n").length > 1) {
    return content.split("\n").map((item, index) => {
      return (
        <p key={index} className="mt-4">
          {item}
        </p>
      );
    });
  }

  return <p className="mt-4">{content}</p>;
}

export function Content({ tableOfContents }) {
  if (!tableOfContents) return null;
  return tableOfContents.map((item, index) => {
    return (
      // if title exists, render title
      // if image exists, render image
      // if content exists, render content

      <div key={index} className="mb-10">
        {item.title && <h3 className="text-2xl font-semibold">{item.title}</h3>}
        {item.image && (
          <img
            className="w-full my-4 rounded-lg"
            src={item.image}
            alt={item.title}
          />
        )}
        {item.content && <FormattedContent content={item.content} />}
      </div>
    );
  });
}

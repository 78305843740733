export function GetInTouch() {
  return (
    <div className="sm:mx-48 mx-8 mt-28">
      <h1 className="sm:text-xl lg:text-4xl text-center font-medium">
        Not a Solar, Drone or EPC company but,
        <br /> DHOOP can be of value to you?
      </h1>
      <div className="flex justify-center">
        <button
          onClick={(e)=>{
            // navigate to #whatisdhoop
            document.getElementById('contact').scrollIntoView({behavior: 'smooth'})
          
          }}
          //   22px padding x 50px padding y tailwind
          className="mt-10 bg-customblue text-white font-medium xs:text-md lg:text-[1.375rem] xs:p-4 lg:px-8 lg:py-[1.25rem] rounded-2xl"
        >
          Get in Touch
        </button>
      </div>
    </div>
  );
}

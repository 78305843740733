import React, { useContext, useEffect, useState } from "react";
import { LuClock8 } from "react-icons/lu";
import { FiEdit2, FiTrash2 } from "react-icons/fi"; // Importing edit and delete icons from react-icons/fi
import { Navbar } from "../Components/Navbar";
import { useNavigate } from "react-router-dom";
import DeleteConfirmationModal from "../Components/DeleteConfirmationModal";
import { CMSContext } from "../BlogPage/index.js";

function ListingBlogsAdmin() {
  const [blogs, setBlogs] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBlogForDeletion, setSelectedBlogForDeletion] = useState(null);
  const navigate = useNavigate();
  const { cmsAPI } = useContext(CMSContext);

  const token = localStorage.getItem('token')

  useEffect(() => {
    fetch(`${cmsAPI}/blog/list`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": token
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setBlogs(data.data); // Adjusting to match the structure provided
      });
  }, []);

  const handleDeleteClick = (blog) => {
    setIsModalOpen(true);
    setSelectedBlogForDeletion(blog);
  };

  const handleConfirmDelete = (postpath) => {
    fetch(`${cmsAPI}/blog/delete/${postpath}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": token
      },
      method: "DELETE",
    })
      .then((response) => {
        if (response.ok) {
          setBlogs(blogs.filter((blog) => blog.postpath !== postpath));
          setIsModalOpen(false);
          setSelectedBlogForDeletion(null);
        }
      })
      .catch((error) => console.error("Error deleting blog:", error));
  };

  if (!blogs.length) {
    return <div>Loading...</div>;
  }

  const handleNavigation = (postpath) => {
    navigate(`/blog/${postpath}`);
  };

  const handleEdit = (postpath) => {
    navigate(`/blog/admin/edit/${postpath}`);
  };

  return (
    <>
      <div className="sticky top-0 z-50 flex justify-center bg-white shadow-md">
        <div className="w-[80%] ">
          <Navbar
            brandLogo={"/assets/icon/blurgs-logo-dark.png"}
            showBookADemoButton={false}
            navItems={[]}
          />
        </div>
      </div>
      <div className="m-20">
        <button onClick={()=>navigate('/blog/admin/create')} className="rounded-lg text-white float-right p-3 text-md bg-blue-500">Create Post</button>
        <h1 className="text-5xl font-semibold">Blogs</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-10">
          {blogs.map((blog, index) => (
            <div
              key={index}
              className="relative bg-white shadow-md rounded-lg overflow-hidden cursor-pointer group"
            >
              {blog.image && (
                <img
                  className="w-full h-48 object-cover"
                  src={blog.image}
                  alt={blog.title}
                  onClick={() => handleNavigation(blog.postpath)}
                />
              )}
              <div
                className="p-4"
                onClick={() => handleNavigation(blog.postpath)}
              >
                <h3 className="text-lg font-semibold mb-2">{blog.title}</h3>
                <h3 className="text-gray-500 mb-2">{blog.date}</h3>
                <p className="text-gray-700 mb-4 line-clamp-3">
                  {blog?.table_of_contents[0]?.content}
                </p>
                <div className="flex items-center gap-1">
                  <LuClock8 size={18} />
                  <p className="text-gray-500">{blog.duration} read</p>
                </div>
              </div>
              <div className="absolute top-2 right-2 flex space-x-2 opacity-0 group-hover:opacity-100 transition-opacity">
                <button
                  onClick={() => handleEdit(blog.postpath)}
                  className="bg-blue-500 text-white p-2 rounded-full hover:bg-blue-600 focus:outline-none"
                  title="Edit"
                >
                  <FiEdit2 size={18} />
                </button>
                <button
                  onClick={() => handleDeleteClick(blog)}
                  className="bg-red-500 text-white p-2 rounded-full hover:bg-red-600 focus:outline-none"
                  title="Delete"
                >
                  <FiTrash2 size={18} />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        id="contact"
        className=""
        style={{ boxShadow: "0px 0px 0px 2px rgba(0,0,0,0.2)" }}
      >
        <div className="lg:grid lg:gap-x-5 lg:grid-cols-3 lg:p-10 lg:max-w-[1440px] lg:container py-10 lg:mx-auto xs:mx-10">
          <div className="lg:cols-span-1">
            <img
              style={{ width: "6rem" }}
              src="/assets/icon/blurgs-logo-dark.png"
            />
            <p className="text-grey font-semibold mt-4">
              © 2024 Blurgs Innovations. <br /> All rights reserved.
            </p>
          </div>
          <div className="lg:cols-span-1">
            <h5 className="font-semibold">Get in Touch</h5>
            <p className="text-grey font-semibold mb-5">
              Blurgs AI, Goodworks Infinity Park, <br /> Electronic City Phase
              1, Bengaluru, Karnataka, India. 560100{" "}
            </p>
            <p
              className="cursor-pointer text-cyan-800 font-semibold"
              onClick={(e) => {
                window.location.href = "mailto:contact@blurgs.com";
              }}
            >
              contact@blurgs.com
            </p>
          </div>
        </div>
      </div>

      <DeleteConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirmDelete}
        item={selectedBlogForDeletion}
      />
    </>
  );
}

export default ListingBlogsAdmin;

import React, { useContext, useEffect, useState } from "react";
import { LuClock8 } from "react-icons/lu";
import { Navbar } from "../Components/Navbar";
import { useNavigate } from "react-router-dom";
import { CMSContext } from '../BlogPage/index.js'

function ListingBlogsUser() {
  const [blogs, setBlogs] = useState([]);
  const navigate = useNavigate()
  const { cmsAPI } = useContext(CMSContext);  // Accessing the context


  useEffect(() => {
    
    fetch(`${cmsAPI}/blog/list`)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setBlogs(data.data); // Adjusting to match the structure provided
      });
  }, []);

  if (!blogs.length) {
    return <div>Loading...</div>;
  }

  const handleNavigation = (postpath) =>{
    navigate(`/blog/${postpath}`)
  }

  return (
    <>
    <div className="sticky top-0 z-50 flex justify-center bg-white shadow-md">
        <div className="w-[80%] ">
          <Navbar
            brandLogo={"/assets/icon/blurgs-logo-dark.png"}
            showBookADemoButton={false}
            navItems={[]}
          />
        </div>
      </div>
    <div className="m-20">
      <h1 className="text-5xl font-semibold">Blogs</h1>
      <div className="flex flex-col md:flex-row mb-8 mt-10">
        <div onClick={()=>handleNavigation(blogs[0].postpath)} className="md:w-1/2 md:mr-4 mb-4 md:mb-0 cursor-pointer ">
          {blogs[0] && (
            <div className="bg-white  rounded-lg overflow-hidden">
              {blogs[0].image && (
                <img
                  className="w-full h-[33rem] object-cover"
                  src={blogs[0].image}
                  alt={blogs[0].title}
                />
              )}
              <div className="mt-4">
                <h2 className="text-xl text-gray-500 mb-2">{blogs[0].title}</h2>
                <h1 className="text-3xl font-semibold">
                  {blogs[0]?.table_of_contents[0]?.content}
                </h1>
                <div className="flex items-center mt-3 gap-1">
                  <LuClock8 size={25} />
                  <p className="text-gray-500">{blogs[0].duration} read</p>
                </div>
              </div>
            </div>
          )}
        </div>
        <div  className="md:w-1/2">
          {blogs.slice(1, 4).map((blog, index) => (
            <div
            onClick={()=>handleNavigation(blog.postpath)}
              key={index}
              className="bg-white shadow-md rounded-lg overflow-hidden mb-4 flex cursor-pointer"
            >
              {blog.image && (
                <img
                  className="w-72 h-56 object-cover flex-shrink-0"
                  src={blog.image}
                  alt={blog.title}
                />
              )}
              <div className="p-4 flex-grow">
                <h3 className="text-lg text-gray-500 mb-2">{blog.date}</h3>
                <h3 className="text-2xl font-semibold mb-5">{blog.title}</h3>
                <p className="text-gray-700 text-lg line-clamp-3">
                  {blog?.table_of_contents[0]?.content}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-36">
        {blogs.slice(4).map((blog, index) => (
          <div
          onClick={()=>handleNavigation(blog.postpath)}

            key={index}
            className="bg-white  rounded-lg overflow-hidden cursor-pointer"
          >
            <div className="p-4">
              {blog.image && (
                <img
                  className="w-full h-48 object-cover mb-4"
                  src={blog.image}
                  alt={blog.title}
                />
              )}
              <h3 className="  text-gray-500 mb-2">Topic</h3>
              <h3 className="text-lg font-semibold mb-2">{blog.title}</h3>
              <div className="flex items-center mt-3 gap-1">
                <LuClock8 size={18} />
                <p className="text-gray-500">{blog.duration} read</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    <div
        id="contact"
        className=""
        style={{ boxShadow: "0px 0px 0px 2px rgba(0,0,0,0.2)" }}
      >
        <div className="lg:grid lg:gap-x-5 lg:grid-cols-3 lg:p-10 lg:max-w-[1440px] lg:container py-10 lg:mx-auto xs:mx-10">
          <div className="lg:cols-span-1">
            <img
              style={{ width: "6rem" }}
              src="/assets/icon/blurgs-logo-dark.png"
            />
            <p className="text-grey font-semibold mt-4">
              © 2024 Blurgs Innovations. <br /> All rights reserved.
            </p>
          </div>
          <div className="lg:cols-span-1">
            <h5 className="font-semibold">Get in Touch</h5>
            <p className="text-grey font-semibold mb-5">
              Blurgs AI, Goodworks Infinity Park, <br /> Electronic City Phase
              1, Bengaluru, Karnataka, India. 560100{" "}
            </p>
            <p
              className="cursor-pointer text-cyan-800 font-semibold"
              onClick={(e) => {
                window.location.href = "mailto:contact@blurgs.com";
              }}
            >
              contact@blurgs.com
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListingBlogsUser;

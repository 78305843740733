import { PrimaryButton } from "./Components/PrimaryButton"



function NavItems(){
    return <ul className="hidden 2xl:flex gap-x-16 m-0 text-darkblue pl-0">
            <li className="cursor-pointer" onClick={(e)=>{
                // navigate to #whatisdhoop
                document.getElementById('whatisdhoop').scrollIntoView({behavior: 'smooth'})
            }}>Product</li>
            <li className="cursor-pointer" onClick={(e)=>{
                // navigate to #whatisdhoop
                document.getElementById('whychoosedhoop').scrollIntoView({behavior: 'smooth'})
            }}>Features</li>
           <li className="cursor-pointer" onClick={(e)=>{
                // navigate to #whatisdhoop
                document.getElementById('howitworks').scrollIntoView({behavior: 'smooth'})
            }}>How it Works</li>
            <li className="cursor-pointer" onClick={(e)=>{
                // navigate to #whatisdhoop
                document.getElementById('contact').scrollIntoView({behavior: 'smooth'})
            }}>Contact</li>
        </ul>
  
}

function Navbar(){
    return <div className="flex justify-between items-center font-semibold">
    <img src="/dhoopassets/icons/dhoop-logo.png" className="h-[50px] object-contain"/>
    <NavItems />
    <div className="hidden md:block">
        <PrimaryButton text={'Book a Demo'} />
    </div>
    
 </div>
}


function JumbotronIOSStyleContent(){

    return <div className="backdrop-blur-sm rounded-[35px] lg:flex lg:py-4 lg:pl-14 lg:pr-9 lg:mt-14 xs:mt-7 xs:mx-10 bg-white/[0.4] mx-auto border justify-between shadow-lg">
        <div className="md:py-16 xs:p-5  grid lg:gap-y-0 lg:gap-y-6 text-left">
            <p className="text-4xl font-semibold text-darkblue">Revolutionizing Solar Plant<br/> Maintainence With</p>
            <img src="/dhoopassets/icons/dhoopgradient.png" className="w-9/12"/>
            <p className="font-medium text-[1.6rem] text-darkblue leading-8">Panel Thermography and Health <br /> Analytics Platform </p>
            <div className="font-medium">
                <PrimaryButton extraWidth={true} text={'Download Brochure'} />
            </div>
        </div>
        <img className="object-contain" src="/dhoopassets/icons/droneandlaptop.png"/>
    </div>
}


export function Jumbotron(){
    return <div className="bg-no-repeat bg-cover relative pb-48" style={{backgroundImage : `URL('/dhoopassets/img/nuno-marques-0GbrjL3vZF4-unsplash.png')`}}>
        <div className="text-xl pt-6 xs:mx-8 lg:mx-52" >
            <Navbar />
        </div>
        
        
        <div className="text-center">
            <div className="inline-block">
                <JumbotronIOSStyleContent />
            </div>
        </div>
        
       
        <div class="absolute bottom-0 left-0 w-full h-20 bg-gradient-to-b from-transparent to-white"></div>
    </div>
}

import {DoubleHeading} from './Components/DoubleHeading'
import { PrimaryButton } from './Components/PrimaryButton'

export function WhatIsDhoop(){

    

    return <div id='whatisdhoop' className="sm:mx-48 mx-8 mt-16">
        <DoubleHeading text={'What is DHOOP?'}/>
        <div className='grid grid-cols-1 lg:grid-cols-2 mt-16 w-full'>
            <div className='lg:pr-48 lg:order-2 order-1'>
                <p className='leading-10 text-justify font-medium text-2xl'>A cutting-edge Data Intelligence platform that utilizes drone-based imagery and deep learning for the precise identification of faults in solar panels.</p>
                <div className='lg:mt-24 xs:mt-8'><PrimaryButton active={true} text={'Download Brochure'} extraWidth={true}/></div>
            </div>
            <img className='lg:order-1 h-md xs:mb-8' src="/dhoopassets/icons/platformdiagonalpair.png"/>
        </div>
    </div>
}
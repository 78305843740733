import { DoubleHeading } from "./Components/DoubleHeading";
import { useState } from "react";

function IconButton({ feature, active = false, setSelectedFeature }) {
  const snakeCaseToText = (text) => {
    return text
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  return (
    <button
      onClick={(e) => {
        setSelectedFeature(feature);
      }}
      style={{ "box-shadow": "0px 0px 16px 0px rgba(0, 0, 0, 0.2)" }}
      className={`lg:px-[5.3125rem] lg:flex xs:p-2 block  justify-center rounded-3xl grid font-medium lg:py-[2.25rem] ${
        active ? "bg-customblue text-white" : "bg-white text-gray-400"
      }`}
    >
      <div className="xs:order-2 lg:order-1 text-center xs:text-sm lg:text-2xl xs:mt-2">{snakeCaseToText(feature.name)}</div>
      <img className="xs:w-10 aspect-square xs:mx-auto xs:order-1 lg:flex justify-end lg:ms-4 xs:mt-2" src={`${active ? feature.activeIcon : feature.icon}`} />
    </button>
  );
}

function FeatureContentItem({ item }) {
  return (
    <div className="flex flex-col justify-center">
      <img src={item.image} className="object-contain lg:h-[15rem] h-[10rem]" />
      <p className="font-medium xs:text-xl lg:text-2xl mt-12">
        <span className="font-semibold">{item.title}</span>
        {item.description}
      </p>
    </div>
  );
}

function FeaturesWithSelector() {
  const features = [
    {
      name: "solar_company",
      activeIcon: "/dhoopassets/icons/panelandsun.svg",
      icon: "/dhoopassets/icons/panelandsungray.svg",
      content: [
        {
          title: "1. Advanced Predictive Analytics: ",
          description:
            "Accurately predict performance issues, enabling proactive maintenance and maximizing efficiency.",
          image: "/dhoopassets/img/advancedpredictiveanalysis.png",
        },
        {
          title: "2. Strategic Maintenance Planning: ",
          description:
            "Craft meticulous strategies for repair scheduling and ensuring year-round optimal performance.",
          image: "/dhoopassets/img/freepik--Device--inject-11.png",
        },
        {
          title: "3. Efficient Data Processing: ",
          description:
            "Our ID system follows the solar plant layout, aiding fault identification for O&M teams minimizing downtime effectively.",
          image: "/dhoopassets/img/efficientdataprocessing.png",
        },
      ],
    },
    {
      name: "drone_company",
      activeIcon: "/dhoopassets/icons/dronetopviewiconactive.svg",
      icon: "/dhoopassets/icons/dronetopviewicon.svg",
      content: [
        {
          title: "1. Advanced Imaging Analysis: ",
          description:
            "Enhance drone imaging capabilities with comprehensive insights, empowering operators to exceed client expectations.",
          image: "/dhoopassets/img/advancedimaginganalysis.png",
        },
        {
          title: "2. Customizable Reporting Solutions: ",
          description:
            "Tailor reports to meet client needs, delivering actionable insights in preferred formats for enhanced communication.",
          image: "/dhoopassets/img/customizablereportingsolutions.png",
        },
        {
          title: "3. Automated Workflow: ",
          description:
            "Upload images, and our platform handles the rest—from stitching to reports, all within a day, empowering streamlined drone operations.",
          image: "/dhoopassets/img/automatedworkflow.png",
        },
      ],
    },
    {
      name: "EPC_company",
      activeIcon: "/dhoopassets/icons/ph_gear-duotone_active.png",
      icon: "/dhoopassets/icons/ph_gear-duotone.png",
      content: [
        {
          title: "Streamlined Project Management: ",
          description:
            "Centralized platform monitors solar assets, enhancing visibility and collaboration for timely, cost-effective delivery.",
          image: "/dhoopassets/img/streamlinedprojectmanagement.png",
        },
        {
          title: "Optimized Asset Lifecycle Management: ",
          description:
            "Maximize solar asset lifespan with digitization and predictive maintenance, ensuring sustainability and minimal downtime.",
          image: "/dhoopassets/img/optimizedassetlifecyclemanagement.png",
        },
        {
          title: "Seamless Workflow Integration: ",
          description:
            "Easily integrate DHOOP into existing workflows and software systems with flexible capabilities for minimal operational disruption.",
          image: "/dhoopassets/img/seamlessworkflowintegration.png",
        },
      ],
    },
  ];
  const [selectedFeature, setSelectedFeature] = useState(features[0]);

  return (
    <div className="mt-[5rem]">
      <div className="flex justify-between space-x-4">
        {features.map((feature, index) => {
          return (
            <IconButton
              active={
                JSON.stringify(selectedFeature) == JSON.stringify(feature)
              }
              setSelectedFeature={setSelectedFeature}
              feature={feature}
            />
          );
        })}
      </div>

      <div className="mt-[5rem] rounded-[3.5rem] border-1 lg:border-2 border-black lg:px-12 lg:py-24 xs:p-6 lg:flex justify-between lg:space-x-14">
        {selectedFeature.content.map((item) => {
          return <FeatureContentItem item={item} />;
        })}
      </div>
    </div>
  );
}

export function WhyChooseDhoop() {
  return (
    <div id="whychoosedhoop" className="sm:mx-48 mx-8 mt-28">
      <DoubleHeading text={"Why Choose DHOOP?"} />
      <FeaturesWithSelector />
    </div>
  );
}

import './Banner.css';


const Banner = ({ blogs, speed = 24000 }) => {
  return (
    <div className="inner" onMouseEnter={(e)=>{
      const sections = document.querySelectorAll('section');
      sections.forEach((section) => {
        section.style.animationPlayState = 'paused';
      });
    }}
    
    onMouseLeave={(e)=>{
      const sections = document.querySelectorAll('section');
      sections.forEach((section) => {
        section.style.animationPlayState = 'running';
      });
    }}
    >
      <div className="wrapper">
        {/* repeat 3 times */}
        {[1,2,3].map((i)=>{
          return <bannersection style={{ "--speed": `${speed}ms` }}>
          {blogs.map(({ id, image,heading,link,icon }) => (
         
              <div  onClick={(e)=>{
                // open in new tab
                window.open(link, '_blank');
              }} className='imgWrapper' style={{position : "relative",'cursor' : 'pointer'}}>
                <p className='text-white text-lg' style={{'zIndex' : '2','fontWeight' : 'bold',position : "absolute","bottom" : "3%","left" : "5%",textAlign : 'left'}}>{heading} <button className='block m-0 p-2 text-sm py-0  rounded-lg bg-white text-black'>Read More</button> </p>
                

                <img src={icon} style={{position : "absolute","top" : "5%","left" : "5%",'width' : "30%",zIndex : 3}} alt={id} />
                <div className="overlay"></div>
                <img src={image} style={{borderRadius : "10px",'height' : "100%",width : '100%','objectFit' : 'cover',objectPosition : 'top'}} alt={id} />
              </div>
          ))}
        </bannersection>
        })}
      </div>
    </div>
  );
};


export function BlogGrid({ blogs }) {

  return (
      <Banner blogs={blogs} />
  );
}



import React, { useState } from "react";

function BookADemo({ setShowModal }) {
  const [currentStep, setCurrentStep] = useState(1);
  const [whatDoYouWantToMonitor, setWhatDoYouWantToMonitor] = useState([]);
  const [numberOfAssetsToTrack, setNumberofAssetsToTrack] = useState("N/A");
  const [
    valueAddedResellerOrSystemsIntegrator,
    setValueAddedResellerOrSystemsIntegrator,
  ] = useState("Yes");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [company, setCompany] = useState("");
  const [country, setCountry] = useState("");

  return (
    <div className="space-y-10">
      {currentStep == 1 && (
        <div className="space-y-10">
          <h1 className="text-center font-bold">
            What do you want to monitor ?
          </h1>
          <div className="flex justify-center space-x-3 bg-white">
            {[
              {
                image: "/assets/icon/container.png",
                title: "Container and port solutions",
              },
              {
                image: "/assets/icon/vessel.png",
                title: "Vessels",
              },
              {
                image: "/assets/icon/buoy.png",
                title: "Buoys",
              },
              {
                image: "/assets/icon/more.png",
                title: "Other",
              },
            ].map((item) => {
              return (
                <div
                  onClick={(e) => {
                    setWhatDoYouWantToMonitor((prev) => {
                      const itemExists = prev.some(
                        (i) => i.title === item.title
                      );
                      if (itemExists) {
                        return prev.filter((i) => i.title !== item.title);
                      } else {
                        return [item, ...prev];
                      }
                    });
                  }}
                  className={`p-5 border-2 cursor-pointer flex flex-col justify-between min-w-56
                  ${
                    // boreder is cyan if selected
                    whatDoYouWantToMonitor.some((i) => i.title === item.title)
                      ? "border-cyan-600 bg-cyan-200"
                      : "border-gray-300"
                  }
                  
                  text-center`}
                >
                  <img className="w-24 mx-auto" src={item.image} />
                  <h4 className="w-56">{item.title}</h4>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {/* step 2 */}
      {currentStep == 2 && (
        <div className="space-y-10">
          <h1>How many assets do you want to track?</h1>
          <div>
            {[
              "1-19",
              "20-49",
              "50-99",
              "100-199",
              "200-999",
              "1000+",
              "N/A",
            ].map((item) => {
              return (
                <div
                  onClick={(e) => {
                    setNumberofAssetsToTrack(item);
                    // setCurrentStep(3);
                  }}
                  className={`p-5 border-2 cursor-pointer flex flex-col justify-between min-w-56
              ${
                // boreder is cyan if selected
                item === numberOfAssetsToTrack
                  ? "border-cyan-600 bg-cyan-200"
                  : "border-gray-300"
              }
              
              text-center`}
                >
                  <h4 className="block mx-auto w-56">{item}</h4>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {/* step 3 */}
      {currentStep == 3 && (
        <div className="space-y-10">
          <h1>Are you a value-added reseller or systems integrator?</h1>
          <div>
            {["Yes", "No"].map((item) => {
              return (
                <div
                  onClick={(e) => {
                    setValueAddedResellerOrSystemsIntegrator(item);
                  }}
                  className={`p-5 border-2 cursor-pointer flex flex-col justify-between min-w-56
            ${
              // boreder is cyan if selected
              item === valueAddedResellerOrSystemsIntegrator
                ? "border-cyan-600 bg-cyan-200"
                : "border-gray-300"
            }
            
            text-center`}
                >
                  <h4 className="block mx-auto w-56">{item}</h4>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {/* step 4 */}
      {currentStep == 4 && (
        <div className="space-y-10 ">
          <h1 className="text-3xl mx-auto text-center">
            How can we contact you?
          </h1>
          <div className="space-y-4 py-5">
            <div className="flex space-x-5 ">
              <input
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                className="p-2 border-2 border-gray-300"
              />
              <input
                type="text"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                className="p-2 border-2 border-gray-300"
              />
            </div>

            <div className="flex flex-col space-y-4">
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                className="p-2 border-2 border-gray-300"
              />
              <input
                type="tel"
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
                className="p-2 border-2 border-gray-300"
              />

              <input
                type="text"
                placeholder="Company"
                value={company}
                onChange={(e) => {
                  setCompany(e.target.value);
                }}
                className="p-2 border-2 border-gray-300"
              />
              <input
                type="text"
                placeholder="Country"
                value={country}
                onChange={(e) => {
                  setCountry(e.target.value);
                }}
                className="p-2 border-2 border-gray-300"
              />
            </div>
          </div>

          <button
            onClick={(e) => {
              if (
                firstName.length == 0 ||
                lastName.length == 0 ||
                email.length == 0 ||
                phoneNumber.length == 0 ||
                company.length == 0 ||
                country.length == 0
              ) {
                alert("Please fill all the fields");
                return;
              }

              const lambdaUrl =
                "https://kktlyahvpyvw7t566asfjv3i4i0agksk.lambda-url.ap-south-1.on.aws/";

              fetch(lambdaUrl, {
                method: "POST",
                body: JSON.stringify({
                  whatDoYouWantToMonitor: whatDoYouWantToMonitor.map(
                    (i) => i.title
                  ),
                  numberOfAssetsToTrack,
                  valueAddedResellerOrSystemsIntegrator,
                  firstName,
                  lastName,
                  email,
                  phoneNumber,
                  company,
                  country,
                }),
              }).then((res) => {
                if (res.ok) {
                  alert("Form submitted successfully");
                  setShowModal(false);
                } else {
                  alert("Error submitting form");
                }
              });
            }}
            className="mx-auto block px-36 text-white p-2 bg-cyan-700"
          >
            Submit
          </button>
        </div>
      )}

      {/* next button */}

      {currentStep < 4 && (
        <button
          onClick={(e) => {
            if (currentStep == 1 && whatDoYouWantToMonitor.length != 0) {
              setCurrentStep(2);
            }

            // else increment each step
            else {
              setCurrentStep(currentStep + 1);
            }
          }}
          className="mx-auto block px-36 text-white p-2 bg-cyan-700"
        >
          Next {">"}
        </button>
      )}
    </div>
  );
}

function BookADemoButton() {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <button
        onClick={(e) => {
          e.preventDefault();
          setShowModal(true);
        }}
        className="bg-[#43B4E2] md:px-7 md:p-2 p-1 px-2  rounded-xl  border border-black font-bold"
      >
        Book A Demo
      </button>

      {showModal ? (
        // absolute, center to screen, partial black overlay, white modal with close button
        <div
          onClick={(e) => {
            setShowModal(false);
          }}
          className="fixed top-0 left-0 w-full h-full bg-black/75 z-[200] flex justify-center items-center"
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="bg-white text-black p-10 rounded-xl"
          >
            <BookADemo setShowModal={setShowModal} />
          </div>
        </div>
      ) : null}
    </>
  );
}

export function Navbar({ navItems, brandLogo, showBookADemoButton = true }) {
  return (
    <div className="flex justify-between items-center p-3 text-xl font-semibold items-center text-white">
      <img src={brandLogo} alt="logo" className="w-32" />
      <ul className="hidden lg:flex space-x-8">
        {navItems.map((navItem, index) => {
          return (
            <a
              href={
                navItem == "Overview"
                  ? "#overview"
                  : navItem == "Why Trident ?"
                  ? "#whychoosetrident"
                  : navItem == "Our Partners"
                  ? "#ourpartners"
                  : navItem == "Contact"
                  ? "#contact"
                  : "#"
              }
            >
              <li key={index} className="nav-item ms-5">
                <h6 className="fw-bold text-light">{navItem}</h6>
              </li>
            </a>
          );
        })}
      </ul>

      {showBookADemoButton && <BookADemoButton />}
    </div>
  );
}

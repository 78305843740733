import { useState, useEffect } from "react";
import { BlogPathInputField } from "./blogpathinputfield";
import { InputField } from "./inputfield";
import { useContext, createContext } from "react";
import { CMSContext } from "../index.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
export function CreatePost() {
  const [tableOfContents, setTableOfContents] = useState([1]);

  const navigate = useNavigate();
  const [uploadProgress, setUploadProgress] = useState({});

  const { cmsAPI } = useContext(CMSContext);

  const token = localStorage.getItem('token')
  



  
  const handleImageUpload = async (file, key) => {
    try {
      // Get signed URL from the server
      const response = await fetch(`${cmsAPI}/blog/image/upload/signedurl`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token
        },
        body: JSON.stringify({
          images: [{ key, name: file.name, contentType: file.type }],
        }),
      });
      const data = await response.json();
      const urlData = data.data[0];

      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("PUT", urlData.url, true);
        xhr.setRequestHeader("Content-Type", file.type);

        // Event listener for upload progress
        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            const percentComplete = Math.round(
              (event.loaded / event.total) * 100
            );
            setUploadProgress((prev) => ({ ...prev, [key]: percentComplete }));
          }
        };

        // On successful upload
        xhr.onload = () => {
          if (xhr.status === 200) {
            setUploadProgress((prev) => ({ ...prev, [key]: 100 }));
            resolve(urlData.url.split("?")[0]); // Return the URL to be used in form data
          } else {
            reject("Failed to upload image");
          }
        };

        // On error
        xhr.onerror = () => reject("Upload failed. Server error occurred.");

        xhr.send(file);
      });
    } catch (error) {
      console.error("Failed to get signed URL", error);
    }
  };

  const handleInputChange = async (e) => {
    if (e.target.type === "file" && e.target.files[0]) {
      const url = await handleImageUpload(e.target.files[0], e.target.name);
      e.target.dataset.publicUrl = url; // Storing the public URL in dataset for form submission
    }
  };

  const validateForm = (data) => {
    const requiredFields = ["blogpath", "title", "date", "duration"];
    for (let field of requiredFields) {
      if (!data[field]) {
        toast.error(`${field} should not be empty`);
        return false;
      }
    }
    return true;
  };

  return (

    <>
    <div className="mt-5 ml-5">
    <button onClick={()=>navigate('/blog/admin/bloglist')} className="rounded-lg text-white  p-3 text-md bg-blue-500">Back to Explore</button>

    </div>


    <div className="max-w-[70%] mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
      <ToastContainer />




      <h1 className="mb-8 text-2xl font-medium">Create Blog</h1>
      <form
        className="space-y-4"
        onSubmit={async (e) => {
          e.preventDefault();
          const formData = new FormData(e.target);
          const data = Object.fromEntries(formData.entries());

          // Append public URLs for images stored in dataset
          for (let entry of formData.entries()) {
            const [key, value] = entry;
            if (key.startsWith("image") && value instanceof File) {
              data[key] = e.target.elements[key].dataset.publicUrl;
            }
          }

          if (!validateForm(data)) return;


          // Post data to server
          const response = await fetch(`${cmsAPI}/blog/create`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": token
            },
            body: JSON.stringify(data),
          });

          const result = await response.json();
          console.log(result);
          
          if (response.ok) {
            toast.success(result.message || "Post created successfully");

            console.log(result.message || "Post created successfully");
            navigate("/blog/admin/bloglist");
            // Additional logic for redirect or notification
          } else {
            toast.error(result.message || "Failed to create post");

          }
        }}
      >
        {/* Form inputs and submit button here */}

        <BlogPathInputField />

        {[
          {
            label: "Enter Title",
            placeholder: "Enter title for blog",
            name: "title",
            type: "text",
          },
          {
            label: "Date",
            name: "date",
            type: "date",
          },
          {
            label: "Duration",
            placeholder: "eg. 5 min",
            name: "duration",
            type: "text",
          },
          {
            label: "Image",
            // placeholder: "eg. 5 min",
            name: "image",
            type: "file",
            onChange: handleInputChange,
          },
        ].map((field) => (
          <InputField key={field.label} {...field} />
        ))}
        {uploadProgress[`image`] && (
          <div style={{ width: "100%" }}>
            <div
              className="bg-blue-500"
              style={{
                height: "10px",
                width: `${uploadProgress[`image`]}%`,
              }}
            >
              {/* Text display inside the progress bar */}
            </div>
            <div
              className="font-bold float-right"
              style={{
                color: "black",
                fontSize: "18px",
              }}
            >
              {uploadProgress[`image`]}%
            </div>
          </div>
        )}

        <h1 className="text-lg font-bold">Table of contents</h1>

        {tableOfContents.map((item, index) => (
          <div className="bg-gray-200 p-8 rounded-lg" key={item}>
            {/* delete button at right side */}
            {tableOfContents.length > 1 && (
              <button
                type="button"
                onClick={() => {
                  setTableOfContents((prev) => {
                    // remove item from table of contents
                    return prev.filter((prevItem) => prevItem !== item);
                  });
                }}
                className="float-right font-bold text-white bg-red-600 p-2 py-1 mb-2 rounded-md"
              >
                Delete
              </button>
            )}

            <InputField
              label={`Title ${index + 1}`}
              placeholder={`Enter title`}
              name={`title_${index + 1}`}
              type="text"
            />
            <br />
            <InputField
              label={`Content ${index + 1}`}
              placeholder={`Enter content ${index + 1}`}
              name={`content_${index + 1}`}
              type="textarea"
            />
            <br />
            <InputField
              label={`Image ${index + 1}`}
              // placeholder={`Enter content ${index + 1}`}
              name={`image_${index + 1}`}
              type="file"
              onChange={handleInputChange}
            />
            {uploadProgress[`image_${index + 1}`] && (
              <div className="mt-5" style={{ width: "100%" }}>
                <div
                  className="bg-blue-500"
                  style={{
                    height: "10px",
                    width: `${uploadProgress[`image_${index + 1}`]}%`,
                  }}
                >
                  {/* Text display inside the progress bar */}
                </div>
                <div
                  className="font-bold float-right "
                  style={{
                    color: "black",
                    fontSize: "18px",
                  }}
                >
                  {uploadProgress[`image_${index + 1}`]}%
                </div>
              </div>
            )}
          </div>
        ))}

        {/* plus button to increase contents */}
        <div>
          <button
            type="button"
            onClick={() =>
              setTableOfContents((prev) => {
                // get the last element, increment by 1, append to array
                const lastElement = prev[prev.length - 1];
                const newElement = lastElement + 1;
                return [...prev, newElement];
              })
            }
            className="text-indigo-600 hover:text-indigo-800"
          >
            + Add Content
          </button>
        </div>

        <div>
          <button
            type="submit"
            className="w-full py-2 px-4 bg-indigo-600 text-white font-medium rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Create Post
          </button>
        </div>
      </form>
    </div>
    </>
  );
}

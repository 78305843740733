import { PrimaryButton } from "./Components/PrimaryButton";
import { useState } from "react";

const lambdaFunctionUrl =
  "https://ngc4m2eup7lfajy6um44cb2biq0ssfmu.lambda-url.ap-south-1.on.aws/";

function FormToBookDemo() {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  return (
    <form className="mt-24 flex flex-col space-y-7" onSubmit={(e)=>{
      e.preventDefault();
      fetch(lambdaFunctionUrl, {
        method: "POST",
        body: JSON.stringify({name, email, message}),
      })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        alert("Form submitted successfully");
      })
      .catch((err) => {
        console.log(err);
        alert("Form submitted successfully");
      });
    }}>
      <input
        type="text"
        placeholder="Name"
        className="font-normal rounded-[1.25rem] p-8 w-full text-xl rounded-lg bg-white"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <input
        type="text"
        placeholder="Email"
        className="font-normal rounded-[1.25rem] p-8 w-full text-xl rounded-lg bg-white"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <textarea
        type="text"
        rows={7}
        placeholder="Message..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        className="font-normal rounded-[1.25rem] p-8 w-full text-xl rounded-lg bg-white"
      />
      <div className="flex justify-center mt-5">
        <PrimaryButton text={"Submit"} />
      </div>
    </form>
  );
}

export function BookADemo() {
  return (
    <div id="contact" className="bg-[#0A3E7C] xs:mt-12 lg:mt-32 pt-24 lg:pb-2 xs:pb-16 xs:px-4 lg:px-0 grid grid-cols-3">
      <div className="col-span-1 xs:hidden lg:block">
        <img src="/dhoopassets/img/Group100.png" className="w-100" />
      </div>
      <div className="lg:col-span-1 xs:col-span-3">
        <h1 className="text-center fw-bold text-[3.125rem] text-white">
          Book a Free Demo
        </h1>
        <FormToBookDemo />
      </div>
      <div className="col-span-1 xs:hidden lg:block">
        <img src="/dhoopassets/img/shakehandvector.png" className="w-100" />
      </div>
    </div>
  );
}

export function InputField({ label, placeholder, name, type,value,onChange }) {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      {type != "textarea" ? (
        <input
          type={type}
          placeholder={placeholder}
          name={name}
          value = {value}
          accept="image/*"
          onChange ={onChange}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      ) : (
        <textarea
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          //   minimum 5 lines
          rows="5"
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        ></textarea>
      )}
    </div>
  );
}

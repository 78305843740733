import { useEffect, useState,useRef } from "react";

function ImageGallery({media}){
    return <>
        <img src={media[0].image} className="rounded-lg" style={{width : "100%",height : 'auto'}} />
        <div className="grid grid-cols-2">
            {media.slice(1).map((media,index)=>{
                return <div key={index} className={`cols-span-1 p-3 ps-0`}>
                    <img className="rounded-lg" src={media.image} style={{width : "100%",height : 'auto'}} />
                </div>
            })}
        </div>
    </>
}

export function MultiMediaInfoWithScrollEvent({items}){

    const [selectedItem,setSelectedItem] = useState(items[0])


    return <>
        <div className="grid xs:mx-5 lg:mx-0 grid-cols-3">
            {items.map((item,index)=>{
                return <div key={index} onClick={(e)=>{
                    setSelectedItem(item)
                }} className={`cols-span-1 p-3 cursor-pointer rounded-lg lg:flex ${selectedItem == item ? "bg-cyan-500 text-white" : ""}`}>
                    <img src={item.icon} style={{width : "3rem"}} className="xs:mx-auto lg:mx-0"/>
                    <div className="lg:ms-4 xs:mx-auto xs:text-center lg:text-left">
                        <p className={`mb-0 ${selectedItem == item ? "text-black" : "text-grey"} font-bold`}>{item.title}</p>
                        <h6 className="font-bold">{item.subtitle}</h6>
                    </div>
                </div>
            })}
        </div>

        <div className="lg:grid xs:mx-10 lg:mx-0 grid-cols-2 mt-16">
            <div className="cols-span-1 px-0" >

                <ImageGallery media={selectedItem.media}/>

            </div>
            <div className="cols-span-1 xs:mt-5 lg:mt-0 lg:ps-10">
                <h6 className="font-bold text-grey">{selectedItem.heading}</h6>
                <h1 className="font-bold text-4xl">{selectedItem.subheading}</h1>
                
                <div className="lg:grid grid-cols-2 mt-5">
                    {
                    selectedItem.media.map((media,index)=>{
                        return <div key={index} className="cols-span-1 mb-5 col-12">
                            <div className="flex lg:mb-0 mb-5">
                                <div>
                                    <img src={media.icon} className="w-24" style={{height : 'auto'}} />
                                </div>
                                
                                <div className="ms-4">
                                    <h5 className={`mb-0 text-xl font-semibold`}>{media.title}</h5>
                                    <p 
                                    style={{lineHeight : '2rem'}}
                                    className="text-grey font-semibold d-none d-lg-block">{media.content}</p>
                                </div>
                            </div>
                            
                        </div>
                    })
                }
                </div>
            </div>
        </div>
    </>
}
import { Navbar } from "../Components/Navbar";
import { IconCard } from "../Components/IconCard";
import { InverseNestedCenteredHeading } from "../Components/InverseNestedCenteredHeading";
import { MultiMediaInfoWithScrollEvent } from "./MultiMediaInfoWithScrollEvent";
import { useRef, useEffect, useState } from "react";
import { ImageTextVertical } from "../Components/ImageTextVertical";
import { BlogGrid } from "../Components/BlogGrid";
import "font-awesome/css/font-awesome.min.css";

function ConnectWithUsForm() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  function handleOnClick(e) {
    e.preventDefault();
    // send POST request
    fetch(
      "https://zdbemyt2qpiz4tuxmfyds4rmfe0pjehj.lambda-url.ap-south-1.on.aws/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email, name: name }),
      }
    ).then((response) => {
      if (response.ok) {
        alert(
          "Thanks for submitting your email. We will get in touch with you soon."
        );
      } else {
        alert("Something went wrong. Please try later");
      }
    });
  }

  return (
    <>
      <h5 className="font-semibold">Connect with Us</h5>
      <form onSubmit={handleOnClick} className="space-y-2">
        <input
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          placeholder="Email"
          className="border-2 border-gray-300 rounded-lg p-2 w-full"
        />
        <br />
        <input
          onChange={(e) => {
            setName(e.target.value);
          }}
          className="border-2 border-gray-300 rounded-lg p-2 w-full"
          placeholder="Name"
        />
        <div className="flex justify-end mt-2">
          <button
            type="submit"
            onClick={handleOnClick}
            className="bg-cyan-500 font-bold px-3 py-2 text-white rounded-lg"
          >
            Connect
          </button>
        </div>
      </form>
    </>
  );
}

export default function TridentLandingPage() {
  return (
    <>
      <div
        style={{
          backgroundColor: "rgba(0,0,10,0.83)",

          backgroundRepeat: "no-repeat",
          backgroundImage: `url("/assets/img/jumbotron.png")`,

          backgroundSize: "cover",
          height: "70vh",
        }}
        className="lg:bg-center xs:bg-[53%] relative"
      >
        <div className="lg:container lg:mx-auto xs:mx-8 lg:max-w-[1440px]">
          <Navbar
            navItems={["Overview", "Why Trident ?", "Our Partners", "Contact"]}
            brandLogo={"/assets/icon/blurgs-logo-light.png"}
          />
        </div>

        <div className="lg:container xs:container-sm lg:mx-auto xs:mx-10 lg:max-w-[1440px] xs:text-2xl lg:text-3xl xl:text-5xl lg:pt-32 pt-16 text-white font-bold">
          <h1 className="lg:w-1/3 xs:w-1/2 text-light fw-semibold">
            Sail through marine data, harnessing intelligence from boat to coast
          </h1>
        </div>

        <div className="text-lg hidden lg:block lg:container xs:mx-10  lg:mx-auto lg:max-w-[1440px] lg:absolute lg:-bottom-[25%] lg:left-1/2 lg:transform lg:-translate-x-1/2">
          <div className="lg:equal-height xs:space-y-10 lg:space-y-0 lg:flex justify-between lg:space-x-4">
            <IconCard
              icon="/assets/icon/cargo-ship.png"
              title="Shipping companies"
              description="Take shipping operations safety and efficiency to the next level by empowering crews with actionable insights"
            />
            <IconCard
              icon="/assets/icon/courthouse.png"
              title="National Authorities"
              description="Quickly identify suspicious movements and illegal activities through behavioural patterns for enhanced decision making."
            />
            <IconCard
              icon="/assets/icon/gear.png"
              title="Fisheries"
              description="Increase your revenue by maximizing your outputs from every visit to the sea."
            />
            <IconCard
              icon="/assets/icon/port.png"
              title="Ports"
              description="Optimize resource allocation and planning through data-driven insights to streamline port traffic and operations"
            />
          </div>
        </div>
      </div>

      <div className="lg:container mx-auto lg:mt-60 mt-20 lg:max-w-[1440px]">
        <InverseNestedCenteredHeading
          heading="Trident"
          subheading="Explore our cutting-edge suite of marine domain awareness tools"
          elementId={"overview"}
        />
        <br /> <br />
        <div className="lg:mb-48 mb-20">
          <MultiMediaInfoWithScrollEvent
            items={[
              {
                title: "Defense Operations",
                subtitle: "For Naval Forces",
                icon: "/assets/icon/battleship.png",
                heading: "Trident for defense operations",
                subheading:
                  "Advanced Capabilities For Maritime Security and Territorial Defense ",
                media: [
                  {
                    image: "/assets/img/darkship detection.webp",
                    icon: "/assets/icon/dark-ship.png",
                    title: "Dark Ship Detection",
                    content:
                      "Utilize a combination of radar, AIS, and satellite image correlation to effectively illuminate 'dark ships', enhancing detection and monitoring capabilities.",
                  },
                  {
                    image: "/assets/img/Defense Operations.webp",
                    icon: "/assets/icon/crisis.png",
                    title: "AI-Based Threat Detection",
                    content:
                      "Employ AI algorithms to detect threats by analyzing ship behavior, enhancing maritime security through advanced pattern recognition. ",
                  },
                  {
                    image: "/assets/img/shipsonmap.png",
                    icon: "/assets/icon/security.png",
                    title: "Large Scale Maritime Monitoring",
                    content:
                      "Conduct real-time, long-term monitoring of ship behavior along maritime borders, ensuring vigilant oversight and enhanced border security. ",
                  },
                ],
              },
              {
                title: "Coastal Surveillance",
                subtitle: "For coast guards",
                icon: "/assets/icon/coast-guard.png",
                heading: "Trident for coast guards ",
                subheading: "Autonomous Monitoring and SAR Operations. ",
                media: [
                  {
                    image:
                      "/assets/img/coastal surveillance and SAR Operations.webp",
                    icon: "/assets/icon/crisis.png",
                    title: "Coastal Threat Monitoring Systems ",
                    content:
                      "Implement autonomous surveillance across extensive coastlines for heightened security, powered by real-time threat alerts. ",
                  },
                  {
                    image: "/assets/img/searchrouteoptimisation.webp",
                    icon: "/assets/icon/route.png",
                    title: "Search Route Optimization",
                    content:
                      "Optimize search patterns by analyzing environmental and historical data, utilizing advanced methods to enhance efficiency and accuracy.",
                  },
                  {
                    image: "/assets/img/searchoperation.png",
                    icon: "/assets/icon/cpu.png",
                    title: "CV / AI Based Search Operations",
                    content:
                      "Enable automatic detection of distress signals, survivors, or objects in the water, ensuring swift and precise response capabilities. ",
                  },
                ],
              },
              {
                title: "Port and Traffic Management",
                subtitle: "For Ports",
                icon: "/assets/icon/cargo-ship-2d.png",
                heading: "Trident for the ports",
                subheading: "Efficient Port & Traffic Management",
                media: [
                  {
                    image: "/assets/img/Port and Traffic  Management.webp",
                    icon: "/assets/icon/resource-allocation.png",
                    title: "Port Operation Management ",
                    content:
                      "Utilize AI-driven scheduling with multiple sensor integration to effectively reduce port congestion and streamline operations. ",
                  },
                  {
                    image: "/assets/img/shipcollission.webp",
                    icon: "/assets/icon/explosion.png",
                    title: "Collision Avoidance Systems",
                    content:
                      "Enable your crews in navigating through busy and dangerous journeys, providing essential support for safer and more efficient voyages. ",
                  },
                  {
                    image: "/assets/img/searchrouteoptimisation.webp",
                    icon: "assets/icon/ocean.png",
                    title: "Live Route Assistance",
                    content:
                      "Providing real-time guidance for ship navigation within ports and along maritime routes, enhancing safety and efficiency in complex navigational environments. ",
                  },
                ],
              },
              {
                title: "Fleet Management",
                subtitle: "For Fleet Operators",
                icon: "/assets/icon/steering-wheel.png",
                heading: "Trident For Fleet Operators",
                subheading:
                  "Ensure Timely Deliveries with Real-time Tracking and Co-ordination",
                media: [
                  {
                    image: "/assets/img/Fleet Management.webp",
                    icon: "/assets/icon/force.png",
                    title: "Detailed Fleet Tracking",
                    content:
                      "Enable fleet operators with real-time kinematic analytics to optimize fleet operations, ensuring enhanced efficiency and strategic decision-making. ",
                  },
                  {
                    image:
                      "/assets/img/standard-quality-control-collage-concept.jpg",
                    icon: "/assets/icon/route.png",
                    title: "Route Optimization",
                    content:
                      "Enable fleet operators with AI-Driven route planning to achieve heightened efficiency and timeliness in their navigational strategies. ",
                  },
                  {
                    image: "/assets/img/searchrouteoptimisation.webp",
                    icon: "/assets/icon/optimizing.png",
                    title: "Predictive Maintenance",
                    content:
                      "Proactively anticipate and prevent maintenance issues, ensuring uninterrupted operations and consistent performance.",
                  },
                ],
              },
              {
                title: "Fishing & Environment",
                subtitle: "For fisheries and fishing authorities",
                icon: "/assets/icon/fishing-net.png",
                heading: "Trident For Fleet Operators",
                subheading:
                  "Promote Sustainable Fishing Practices with Advanced Detection and Monitoring",
                media: [
                  {
                    image: "assets/img/fishing.webp",
                    icon: "/assets/icon/hotspot.png",
                    title: "HotSpot Detection and Monitoring",
                    content:
                      "Identify fishing hotspots and monitor trends for informed decision-making and strategic actions.",
                  },
                  {
                    icon: "/assets/icon/planet-earth.png",
                    title: "Environmental Monitoring Systems",
                    content:
                      "Implement vigilant monitoring of marine ecosystems to safeguard against overfishing, ensuring sustainable and responsible practices.",
                  },
                ],
              },

              {
                title: "Smart Shipping",
                subtitle: "For Vessels",
                icon: "/assets/icon/radar.png",
                heading: "Trident for the vessels",
                subheading: "An Advanced Situational Awareness System.",
                media: [
                  {
                    image: "/assets/img/smartshipping.webp",
                    icon: "/assets/icon/awareness.png",
                    title: "Advanced Ship Awareness",
                    content:
                      "Empower your crew with the ability to swiftly and effortlessly identify targets, even in conditions of almost zero visibility and within densely populated waters. ",
                  },
                  {
                    image: "/assets/img/shiphud.webp",
                    icon: "/assets/icon/navigation.png",
                    title: "Assisted Guidance",
                    content:
                      "Offer your team enhanced guidance and maneuvering assistance for a safer and more secure navigation experience. ",
                  },
                  {
                    image: "/assets/img/shipradar.webp",
                    icon: "/assets/icon/hud.png",
                    title: "Smart HUDs for Vital Info",
                    content:
                      "Equip your crew with vital information via user-friendly Heads-Up Displays, designed for optimal efficiency and clarity. ",
                  },
                ],
              },
            ]}
          />
        </div>
        <InverseNestedCenteredHeading
          heading="Why Choose Trident ?"
          subheading="Here are a few reasons why our customers choose Trident"
          elementId={"whychoosetrident"}
        />
        <br />
        <div className="xs:mx-10 lg:mx-0 grid xs:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-5 gap-10">
          {[
            {
              image: "/assets/img/tower-820001_640.jpg",
              title: "Multi-Modal Data",
              subtitle:
                "Correlating diverse data sources for comprehensive analysis. ",
            },
            {
              image: "/assets/img/shipsonmap.png",
              title: "Scalable Analytics",
              subtitle:
                "Adaptable insights for stakeholders, from single ship to global oceans. ",
            },
            {
              image: "/assets/img/pexels-negative-space-97077.jpg",
              title: "Efficient Algorithms",
              subtitle:
                "Optimizing computing resources to reduce costs and enhance performance",
            },
            {
              image: "/assets/img/regional analytics.webp",
              title: "Regional Analytics",
              subtitle:
                "Utilizing smart regions for impactful, cost-effective analysis. ",
            },
            {
              image: "/assets/img/wepik-export-20240215144142Sz0S.jpeg",
              title: "Smart Alert System",
              subtitle:
                "Instantly delivering crucial information to stakeholders in real-time.",
            },
            {
              image:
                "/assets/img/boat-cuts-through-serene-black-waters-trailing-froth.png",
              title: "Counter Measures",
              subtitle:
                "Addressing data inconsistencies, spoofing, and untracked vessels effectively.",
            },
          ].map((item, index) => {
            return (
              <div>
                <ImageTextVertical
                  image={item.image}
                  title={item.title}
                  subtitle={item.subtitle}
                />
              </div>
            );
          })}
        </div>
        <br /> <br /> <br /> <br /> <br />
        <div>
          <InverseNestedCenteredHeading subheading="Resources" />
          <br />
          <BlogGrid
            blogs={[
              {
                heading:
                  "BEL INKS PROCUREMENT DEAL WITH BLURGS INNOVATIONS FOR CUTTING-EDGE ARTIFICIAL INTELLIGENCE (AI) TECH",
                image: "/assets/img/beloffice.jpg",
                link:
                  "https://www.indiandefensenews.in/2024/01/bel-inks-procurement-deal-with-blurgs.html",
                icon: "/assets/icon/BEL.png",
              },
              {
                heading:
                  "BEL Takes the Plunge into AI-Powered Maritime Security with iDEX Winner Blurgs Innovations",
                image: "/assets/img/roshanidexaward.png",
                link:
                  "https://idrw.org/bel-takes-the-plunge-into-ai-powered-maritime-security-with-idex-winner-blurgs-innovations/",
                // icon : '/assets/icon/IDRW.png'
              },
              {
                heading:
                  "How India Plans to Use Artificial Intelligence to Secure Its Vast Coastline",
                image:
                  "/assets/img/711818_0_0_2048_1366_1440x900_80_0_1_41ad3108c4b93e93fe9e9ff04d220f2a.jpg.webp",

                link:
                  "https://sputniknews.in/20240101/how-india-plans-to-use-artificial-intelligence-to-secure-its-vast-coastline-6049249.html",
                icon: "/assets/icon/Sputnik.png",
              },
              {
                heading:
                  "Guardians of the Waves: Harnessing AI for Coast Guard Excellence",
                image: "/assets/img/indiancoastguardday.png",

                link:
                  "https://indiaai.gov.in/article/guardians-of-the-waves-harnessing-ai-for-coast-guard-excellence",
                icon: "/assets/icon/INDIAai.png",
              },
              {
                heading:
                  "Blurgs secured the Nasscom Emerge 50  at the Nasscom Future Forge 2023",
                image: "/assets/img/roshannasscom.jpeg",

                link:
                  "https://nasscom.in/emerge50/winners/blurgs-innovations.html",
                icon: "/assets/icon/nasscom.png",
              },
              {
                heading:
                  "Rescue in Rush: Tackling Search & Rescue (SAR) Missions Amid Rising Marine Traffic",
                image:
                  "/assets/img/coastal surveillance and SAR Operations.jpg",
                link:
                  "https://medium.com/@blurgs_ai/rescue-in-rush-tackling-search-rescue-sar-missions-amid-rising-marine-traffic-8ac23b680640",
                icon: "/assets/icon/3721675_medium_icon.png",
              },
            ]}
          />
        </div>
        <br /> <br /> <br />
        <br /> <br /> <br />
        <br /> <br /> <br />
        <InverseNestedCenteredHeading
          elementId={"ourpartners"}
          subheading={"Our Partners"}
        />
        <div className="lg:grid grid-cols-3 lg:mt-20">
          {[
            "/assets/icon/bharatelectronics.png",
            "/assets/icon/indiannavy.png",
            "/assets/icon/vayam.png",
          ].map((item, index) => {
            return (
              <img
                className={`mx-auto ${
                  index == 1 || index == 0 ? "" : "xs:mt-20"
                }  lg:mt-0`}
                style={{ width: "50%" }}
                src={item}
              />
            );
          })}
        </div>
        <br />
        <br /> <br /> <br />
        <br />
        <br /> <br /> <br />
      </div>
      <div
        id="contact"
        className=""
        style={{ boxShadow: "0px 0px 0px 2px rgba(0,0,0,0.2)" }}
      >
        <div className="lg:grid lg:gap-x-5 lg:grid-cols-3 lg:p-10 lg:max-w-[1440px] lg:container py-10 lg:mx-auto xs:mx-10">
          <div className="lg:cols-span-1">
            <img
              style={{ width: "6rem" }}
              src="/assets/icon/blurgs-logo-dark.png"
            />
            <p className="text-grey font-semibold mt-4">
              © 2024 Blurgs Innovations. <br /> All rights reserved.
            </p>
          </div>
          <div className="lg:cols-span-1">
            <h5 className="font-semibold">Get in Touch</h5>
            <p className="text-grey font-semibold mb-5">
              Blurgs AI, Goodworks Infinity Park, <br /> Electronic City Phase
              1, Bengaluru, Karnataka, India. 560100{" "}
            </p>
            <p
              className="cursor-pointer text-cyan-800 font-semibold"
              onClick={(e) => {
                window.location.href = "mailto:contact@blurgs.com";
              }}
            >
              contact@blurgs.com
            </p>
          </div>
          <div className="lg:cols-span-1">
            <ConnectWithUsForm />
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
  useNavigate,
  Navigate,
} from "react-router-dom";

import { CreatePost } from "./createpost";
import AdminLogin from "./adminLogin";
import { CMSContext } from "../BlogPage/index.js";
import { useContext } from "react";
import ListingBlogsAdmin from "./ListingBlogsAdmin.jsx";
import { EditBlogs } from "./EditBlogs.jsx";

// function EditPost() {
//   const postpath = useParams().postpath;
//   return <div>Edit Post: {postpath}</div>;
// }


function RequireAuth({ children }) {
  const { cmsAPI } = useContext(CMSContext);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  // Check if there is a token
  if (!token) {
    // Redirect to the login page if no token is found
    return <Navigate to="/blog/admin/login" replace />;
  }

  // Optional: Verify token with the backend before proceeding
  fetch(`${cmsAPI}/admin/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      'token' : token
    }),
  })
  .then(response => response.json())
  .then(data => {
    if (!data.isValid) {
      // Handle invalid token case
      localStorage.removeItem('token'); // Remove invalid token
      navigate('/blog/admin/login'); // Redirect to login
    }
  })
  .catch(error => {
    console.error('Error verifying token:', error);
    navigate('/blog/admin/login'); // Redirect to login on error
  });

  // If token exists and is presumed valid, render the child components
  return children;
}

export function AdminRoutes() {
  return (
    <Routes>
      <Route path="login" element={<AdminLogin/>} />
      <Route path="/" element={<div>Overview</div>} />  
      <Route path="bloglist" element={<RequireAuth><ListingBlogsAdmin/></RequireAuth>} />
      <Route path="edit/:postpath" element={<RequireAuth>
          <EditBlogs />
        </RequireAuth>} />
      <Route path="create" element={
        <RequireAuth>
          <CreatePost />
        </RequireAuth>
      } />
    </Routes>
  );
}

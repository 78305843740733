import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
} from "react-router-dom";

import { useContext, createContext } from "react";
import { Content } from "./content";
import { Navbar } from "../Components/Navbar";
import { AdminRoutes } from "./admin";
import ListingBlogsUser from "./ListingBlogsUser";


function PostList() {
  return <div>Post List</div>;
}

function PostDetail() {
  const { postpath } = useParams();
  const [postJson, setPostJson] = useState({});
  const { cmsAPI } = useContext(CMSContext);  // Accessing the context


  useEffect(() => {
    
    fetch(`${cmsAPI}/blog/${postpath}`)  // Using cmsAPI from the context
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setPostJson(data);
      });
  }, []);

  return (
    <>
      {/* navbar */}
      <div className="sticky top-0 z-50 flex justify-center bg-white shadow-md">
        <div className="w-[80%] ">
          <Navbar
            brandLogo={"/assets/icon/blurgs-logo-dark.png"}
            showBookADemoButton={false}
            navItems={[]}
          />
        </div>
      </div>
      <div className="flex justify-center mt-24 mx-4">
        <div className="xl:w-1/3 space-y-10">
          <div className="text-center text-4xl font-medium">
            {postJson.title}
          </div>
          <div className="flex justify-center space-x-4">
            <small>{postJson.date}</small>
            <small>{postJson.duration}</small>
          </div>

          <div className="flex justify-center">
            <img src={postJson.image} alt={postJson.title} />
          </div>
        </div>
      </div>

      {/* 20% and 80% of widths */}

      <div className="flex justify-center mt-24">
        <div className="xl:w-2/3 space-y-10">
          <div className="grid grid-cols-5 space-x-12 mt-10">
            <div className="xl:col-span-1 hidden xl:block">
              {/* all keys from table_of_contents */}
              {/* border border-black pb-2 rounded-lg */}
              <div className="border border-gray-200 pb-2 rounded-lg">
                <div className=" mb-2 py-2 border border-gray-200 rounded-tl-lg rounded-tr-lg">
                  <p className="ms-2 font-semibold">Table of Contents</p>
                </div>
                <ul className="px-2">
                  {postJson.table_of_contents &&
                    Object.values(postJson.table_of_contents).map(
                      (item, index) => (
                        <li className="mb-1" key={index}>
                          <p className="text-gray-600">
                            {/* right arrow */}
                            &#8594; {" " + item.title}
                          </p>
                        </li>
                      )
                    )}
                </ul>
              </div>
            </div>
            <div className="col-span-4">
              <Content tableOfContents={postJson.table_of_contents} />
            </div>
          </div>
        </div>
      </div>

      {/* footer */}
      <div
        id="contact"
        className=""
        style={{ boxShadow: "0px 0px 0px 2px rgba(0,0,0,0.2)" }}
      >
        <div className="lg:grid lg:gap-x-5 lg:grid-cols-3 lg:p-10 lg:max-w-[1440px] lg:container py-10 lg:mx-auto xs:mx-10">
          <div className="lg:cols-span-1">
            <img
              style={{ width: "6rem" }}
              src="/assets/icon/blurgs-logo-dark.png"
            />
            <p className="text-grey font-semibold mt-4">
              © 2024 Blurgs Innovations. <br /> All rights reserved.
            </p>
          </div>
          <div className="lg:cols-span-1">
            <h5 className="font-semibold">Get in Touch</h5>
            <p className="text-grey font-semibold mb-5">
              Blurgs AI, Goodworks Infinity Park, <br /> Electronic City Phase
              1, Bengaluru, Karnataka, India. 560100{" "}
            </p>
            <p
              className="cursor-pointer text-cyan-800 font-semibold"
              onClick={(e) => {
                window.location.href = "mailto:contact@blurgs.com";
              }}
            >
              contact@blurgs.com
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
export const CMSContext = createContext();

export function BlogRoutes() {
  const cmsAPI = "https://blog-cms-backend.blurgs.ai";
  // const cmsAPI = "http://127.0.0.1:5000";
  // create context

  return (
    <CMSContext.Provider value={{ cmsAPI }}>
      <Routes>
        <Route path="admin/*" element={<AdminRoutes />} />
        <Route path="/" element={<PostList />} />
        <Route path="list" element={<ListingBlogsUser />} />
        {/* depending on post */}
        <Route path=":postpath" element={<PostDetail />} />
      </Routes>
    </CMSContext.Provider>
  );
}

import { useState, useEffect, useContext } from "react";
import { BlogPathInputField } from "./createpost/blogpathinputfield.js";
import { InputField } from "./createpost/inputfield.js";
import { CMSContext } from "./index.js";
import { useNavigate, useParams } from "react-router-dom";

export function EditBlogs() {
  const [tableOfContents, setTableOfContents] = useState([]);
  const { cmsAPI } = useContext(CMSContext);
  const postpath = useParams().postpath;
  const navigate = useNavigate();
  const [postJson, setPostJson] = useState({});
  const [imageUrls, setImageUrls] = useState({});
  const [uploadProgress, setUploadProgress] = useState({});


  const token = localStorage.getItem('token')

  useEffect(() => {
    console.log(imageUrls);
  }, [imageUrls]);

  useEffect(() => {
    fetch(`${cmsAPI}/blog/${postpath}`)
      .then((res) => res.json())
      .then((data) => {
        setPostJson(data);
        setTableOfContents(data.table_of_contents || []);
        const initialImageUrls = {};
        if (data.image) {
          initialImageUrls["image"] = data.image;
        }
        data.table_of_contents.forEach((content, index) => {
          if (content.image) {
            initialImageUrls[`image_${index + 1}`] = content.image;
          }
        });
        setImageUrls(initialImageUrls);
      });
  }, [cmsAPI, postpath]);

  const handleImageUpload = async (file, key) => {
    const response = await fetch(`${cmsAPI}/blog/image/upload/signedurl`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": token
      },
        body: JSON.stringify({
        images: [{ key, name: file.name, contentType: file.type }],
      }),
    });
    const data = await response.json();
    const urlData = data.data[0];
  
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", urlData.url, true);
      xhr.setRequestHeader("Content-Type", file.type);
  
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = Math.round((event.loaded / event.total) * 100);
          setUploadProgress(prev => ({ ...prev, [key]: percentComplete }));
        }
      };
  
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(urlData.url.split("?")[0]);
        } else {
          reject("Failed to upload image");
        }
      };
  
      xhr.onerror = reject;
      xhr.send(file);
    }).then(url => {
      setImageUrls(prev => ({ ...prev, [key]: url }));
      return url.split('?')[0];
    });
  };
  

  const handleInputChange = async (e) => {
    if (e.target.type === 'file' && e.target.files[0]) {
      await handleImageUpload(e.target.files[0], e.target.name);
    } else {
      const { name, value } = e.target;
      setPostJson((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleTableOfContentsChange = (index, field, value) => {
    const updatedTableOfContents = tableOfContents.map((content, i) => {
      if (i === index) {
        return { ...content, [field]: value };
      }
      return content;
    });
    setTableOfContents(updatedTableOfContents);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = {
      ...postJson,
      table_of_contents: tableOfContents.map((item, index) => ({
        ...item,
        image: imageUrls[`image_${index + 1}`] || item.image,
      })),
      image: imageUrls["image"],
    };

    const response = await fetch(`${cmsAPI}/blog/edit/${postpath}`, {
      method: "PUT",
       headers: {
    "Content-Type": "application/json",
    "Authorization": token
  },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      console.log("Post updated successfully");
      navigate(`/blog/${postpath}`);
    } else {
      console.error("Failed to update post");
    }
  };

  return (
    <>
    <div className="mt-5 ml-5">
    <button onClick={()=>navigate('/blog/admin/bloglist')} className="rounded-lg text-white  p-3 text-md bg-blue-500">Back to Explore</button>

    </div>
    <div className="max-w-[70%] mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
        
      <h1 className="mb-8 text-2xl font-medium">Edit Blog</h1>
      <form className="space-y-4" onSubmit={handleSubmit}>
        <BlogPathInputField defaultValue={postJson.postpath} disabled={true} />

        {[
          {
            label: "Enter Title",
            placeholder: "Enter title for blog",
            name: "title",
            type: "text",
            value: postJson.title || "",
            onChange: handleInputChange,
          },
          {
            label: "Date",
            name: "date",
            type: "date",
            value: postJson.date || "",
            onChange: handleInputChange,
          },
          {
            label: "Duration",
            placeholder: "eg. 5 min",
            name: "duration",
            type: "text",
            value: postJson.duration || "",
            onChange: handleInputChange,
          },
        ].map((field) => (
          <InputField key={field.label} {...field} />
        ))}

        <InputField
          onChange={handleInputChange}
          label="Image"
          name="image"
          type="file"
        />
       {uploadProgress[`image`] && (
          <div style={{ width: "100%" }}>
            <div
              className="bg-blue-500"
              style={{
                height: "10px",
                width: `${uploadProgress[`image`]}%`,
              }}
            >
              {/* Text display inside the progress bar */}
            </div>
            <div
              className="font-bold float-right"
              style={{
                color: "black",
                fontSize: "18px",
              }}
            >
              {uploadProgress[`image`]}%
            </div>
          </div>
        )}

        {imageUrls["image"] && (
          <img
            className="w-64 h-64"
            src={imageUrls["image"]}
            alt="Main Image"
          />
        )}

        <h1 className="text-lg font-bold">Table of Contents</h1>

        {tableOfContents.map((item, index) => (
          <div className="bg-gray-200 p-4 rounded-lg" key={index}>
            {tableOfContents.length > 1 && (
              <button
                type="button"
                onClick={() => {
                  setTableOfContents((prev) => {
                    return prev.filter((_, i) => i !== index);
                  });
                }}
                className="float-right font-bold text-white bg-red-600 p-2 py-1 mb-2 rounded-md"
              >
                Delete
              </button>
            )}

            <InputField
              label={`Title ${index + 1}`}
              placeholder={`Enter title`}
              name={`title_${index + 1}`}
              type="text"
              value={item.title || ""}
              onChange={(e) =>
                handleTableOfContentsChange(index, "title", e.target.value)
              }
            />
            <br />
            <InputField
              label={`Content ${index + 1}`}
              placeholder={`Enter content ${index + 1}`}
              name={`content_${index + 1}`}
              type="textarea"
              value={item.content || ""}
              onChange={(e) =>
                handleTableOfContentsChange(index, "content", e.target.value)
              }
            />
            <br />
            <InputField
              label={`Image ${index + 1}`}
              name={`image_${index + 1}`}
              type="file"
              onChange={handleInputChange}
            />
            {uploadProgress[`image_${index + 1}`] && (
              <div className="mt-5" style={{ width: "100%" }}>
                <div
                  className="bg-blue-500"
                  style={{
                    height: "10px",
                    width: `${uploadProgress[`image_${index + 1}`]}%`,
                  }}
                >
                  {/* Text display inside the progress bar */}
                </div>
                <div
                  className="font-bold float-right "
                  style={{
                    color: "black",
                    fontSize: "18px",
                  }}
                >
                  {uploadProgress[`image_${index + 1}`]}%
                </div>
              </div>
            )}
            {imageUrls[`image_${index + 1}`] && (
              <img
                className="w-64 h-64 m-2"
                src={imageUrls[`image_${index + 1}`]}
                alt={`Content ${index + 1}`}
              />
            )}
          </div>
        ))}

        <div>
          <button
            type="button"
            onClick={() =>
              setTableOfContents((prev) => {
                return [...prev, { title: "", content: "" }];
              })
            }
            className="text-indigo-600 hover:text-indigo-800"
          >
            + Add Content
          </button>
        </div>

        <div>
          <button
            type="submit"
            className="w-full py-2 px-4 bg-indigo-600 text-white font-medium rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Edit Post
          </button>
        </div>
      </form>
    </div>
    </>
  );
}

export function InverseNestedCenteredHeading({
  heading,
  subheading,
  elementId = null,
}) {
  return (
    <div id={elementId} className="text-center xs:mx-10 lg:mx-0">
      <h6 className="text-cyan-600 font-bold">{heading}</h6>
      <h1 className="font-semibold text-3xl">{subheading}</h1>
    </div>
  );
}

import logo from "./logo.svg";
import "./App.css";
import DhoopLandingPage from "./DhoopLandingPage";
import TridentLandingPage from "./TridentLandingPage";
import { BlogRoutes } from "./BlogPage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/dhoop" exact element={<DhoopLandingPage />} />
          <Route path="/" exact element={<TridentLandingPage />} />
          <Route path="/blog/*" exact element={<BlogRoutes />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.equal-height {
  display: flex;
}

.equal-height [class^="col-"] {
  display: flex;
  flex-direction: column;
}

.equal-height [class^="col-"] > * {
  flex-grow: 1;
} 

.text-black {
  color: black;
}

.text-grey {
  color: rgb(105, 105, 105);
}

.bg-black {
  background-color: black;
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";AACA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":["\n.equal-height {\n  display: flex;\n}\n\n.equal-height [class^=\"col-\"] {\n  display: flex;\n  flex-direction: column;\n}\n\n.equal-height [class^=\"col-\"] > * {\n  flex-grow: 1;\n} \n\n.text-black {\n  color: black;\n}\n\n.text-grey {\n  color: rgb(105, 105, 105);\n}\n\n.bg-black {\n  background-color: black;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
